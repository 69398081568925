import React from "react"
import { graphql, Link } from "gatsby"
import styled from "@emotion/styled"
import { keyframes }  from "@emotion/react";
import Burger from '../components/Burger';
import SiteHeader  from '../components/SiteHeader';

const BurgerContainer = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 2rem;
  border: solid 0.5rem black;
  width: 100%;
  box-sizing: border-box;
  img {
    width: 100%;
  }
`;

const stripBgVariants = {
  'pink': `
    background: hotpink;
    color: black;
  `,
  'pink-strips': `
    background: hotpink;
    background: repeating-linear-gradient(-45deg, hotpink, hotpink 20px, black 22px, black 23px);
  `,
  'black': `
    background: black;
  `,
  'black-strips': `
    background: black;
    background: repeating-linear-gradient(45deg, black, black 20px, hotpink 22px, hotpink 23px);
  `,
  'yellow': `
    background: yellow;
    color: black;
  `,
  'yellow-strips': `
    background: yellow;
    background: repeating-linear-gradient(45deg, yellow, yellow 20px, black 22px, black 23px);
    color: black;
  `
};

const Strip = styled.div`
  color: white;
  ${({ variant }) => stripBgVariants[variant]}
  padding: 2rem;

  @media only screen and (min-width: 992px) {
    padding: 2rem 4rem;
  }
`;

const StripTitle = styled(Strip)`
  padding: 1rem;
  @media only screen and (min-width: 992px) {
    padding: 2rem 4rem;
  }
`;

const PageTitle = styled.h1`
  font-family: 'Fredoka One', cursive;
  margin: 0;
  a {
    color: hotpink;
    font-size: 3rem;
    text-decoration: none;
  }
`;

const FillingTitle = styled.h1`
  font-family: 'Fredoka One', cursive;
  font-size: 5rem;
  margin: 0;
`;

const FillingType = styled.h2`
  font-family: 'Fredoka One', cursive;
  font-size: 2.5rem;
  margin: 0;
`;

const SuggestionsTitle = styled.h3`
  font-family: 'Fredoka One', cursive;
  font-size: 2.5rem;
  margin: 0;
`;

const AllCombinationsTitle =  styled.h3`
  font-family: 'Fredoka One', cursive;
  font-size: 2.5rem;
  margin: 0 0 1rem 0;
`;

const LightsContainer = styled.div`
  & + & {
    margin-top: 1.5rem;
  }
  hr {
    border-color: black;
  }
`

const LightsName = styled.div`
  font-family: 'Fredoka One', cursive;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
`

const FillingConstructionOptions = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  align-items: start;
  @media only screen and (min-width: 992px) {
    grid-template-columns: auto 1fr;
  }
`;

const BurgerLinkContainer = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 1fr 1fr;
`

const bounce = keyframes`
  from, 20%, 53%, 80%, to {
    transform: translateX(0);
  }

  40%, 43% {
    transform: translateX(15px);
  }

  70% {
    transform: translateX(8px);
  }

  90% {
    transform: translateX(2px);
  }
`

const BurgerLink = styled(Link)`
  color: black;
  text-decoration: none;
  font-family: 'Fredoka One', cursive;
  border: none;
  justify-items: self-start;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 1rem;
  font-size: 1.5rem;
  background: white;
  transition: background 0.5s;
  cursor: pointer;
  box-shadow: 0 0 0.125rem 0.125rem black;

  &:after {
    display: block;
    line-height: 1rem;
    transition: background 0.5s;
    margin-right: 1rem;
  }

  &:hover {
    background: yellow;
  }

  &:after {
    content: '➜';
  }

  &:hover:after {
    animation: ${bounce} 1s ease infinite;
  }
`;



export default function Template({
  data, pageContext, ...props // this prop will be injected by the GraphQL query below.
}) {


  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const { construction, spices } = pageContext;

  return (
    <div>
      <SiteHeader />
      <Strip variant="yellow">
        <FillingType>Burger Filling</FillingType>
        <FillingTitle>{frontmatter.title}</FillingTitle>
        <div
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </Strip>
      <Strip variant="black-strips">
        <SuggestionsTitle>BurgerBot's Suggestions</SuggestionsTitle>
        <p>Suggestions coming soon...</p>
      </Strip>
      <Strip variant="pink-strips">
        <AllCombinationsTitle>All possible combination...</AllCombinationsTitle>
        {construction.map((constructionItem) => {
          return (
          <LightsContainer key={constructionItem.node.frontmatter.slug}>
            <LightsName>{constructionItem.node.frontmatter.title}</LightsName>
            <hr />
            <FillingConstructionOptions>
              <BurgerContainer>
                <Burger construction={constructionItem.node} filling={markdownRemark} />
              </BurgerContainer>
              <BurgerLinkContainer>
                {spices.map((spice) => {
                  return <BurgerLink key={spice.node.frontmatter.slug} to={`/${constructionItem.node.frontmatter.slug}/${frontmatter.slug}/${spice.node.frontmatter.slug}`} >{spice.node.frontmatter.title}</BurgerLink>;
                })}
              </BurgerLinkContainer>
            </FillingConstructionOptions>
          </LightsContainer>
          );
        })}
      </Strip>
    </div>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`;
